import Gallery from "../../components/Gallery/Gallery";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Typography} from "@material-ui/core";
import {Helmet} from "react-helmet";
import {useEffect, useState} from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '85px',
    padding: '15px',
  }
}));

const Favourites = () => {
  const classes = useStyles();
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetch("/img/favourites/favourites.json")
      .then(response => {
        return response.json();
      })
      .then(data => {
        setImages(data);
      });
  }, [setImages]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Favourites | Thomas Bezuidenhout</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h1" align="center">
            Favourites
          </Typography>
          <Typography gutterBottom={true} align="center">
            These are some of my favourite images that I've shot during the 2020 pandemic. Life has been boring, and getting
            out every now and again to shoot random snaps has been quite relaxing in the midst of all this chaos. Enjoy!
          </Typography>
          {/* <Typography gutterBottom={true}>
            These are some of my favourite images that have been shot during my short but utterly enjoyable first few months
            of photography. Even in times like these, one should still be able to find beauty in far away places.
          </Typography>
          <Typography gutterBottom={true}>
            I got my first camera in March of 2020. Even though I haven't been able to venture far, I fell in love with it immediately.
            There's something oddly therapeutic in thinking everything over. The composition of your image, the right lens to use for the
            shot, whether or not to wait for something more interesting to come by. Even when it didn't work out, a day spent with a
            camera and some music blasting in my ears has kept me sane during a time in which the world has lost too many souls. Souls who might
            have viewed beauty. So I try to see what they couldn't, and try to capture what I see while I'm at it.
          </Typography> */}
        </Grid>
        <Grid item xs={12}>
          <Gallery images={images} />
        </Grid>
      </Grid>
    </div>
  )
};

export default Favourites;
