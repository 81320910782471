import './App.css';
// import { makeStyles } from "@material-ui/core";
import Home from "./pages/Home/Home";
import { Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';
import Favourites from "./pages/Favourites/Favourites";
import Contact from "./pages/Contact/Contact";
import BlackAndWhite from "./pages/BlackAndWhite/BlackAndWhite";
import Random from "./pages/Random/Random";
import Bavaria from "./pages/Bavaria/Bavaria";
import withTracker from './withTracker';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1
//   }
// }));

function App() {
  // const classes = useStyles();
  ReactGA.initialize('UA-201777948-1');
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <Switch>
      <Route path="/favourites" component={withTracker(Favourites)} /> 
      <Route path="/contact" component={withTracker(Contact)} />
      <Route path="/black-and-white" component={withTracker(BlackAndWhite)} />
      <Route path="/random" component={withTracker(Random)} />
      <Route path="/bavaria" component={withTracker(Bavaria)} />
      <Route path="/" component={withTracker(Home)} />
    </Switch>
  );
}

export default App;
