import './Header.css';

import * as React from 'react';
import {useState} from 'react';

import {
  AppBar,
  Container,
  Divider,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar
} from "@material-ui/core";
import {Home} from "@material-ui/icons";

import SideDrawer from '../SideDrawer/SideDrawer';
import HideOnScroll from "../HideOnScroll/HideOnScroll";
import {Link} from "react-router-dom";

const useStyles = makeStyles({
  toolbar: {
    // height: '50px',
    // background: 'transparent',
    // boxShadow: 'none'
  },
  navbarDisplayFlex: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  navDisplayFlex: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  linkText: {
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: 'white'
  }
});

const navLinks = [
  { title: 'Favourites', path: '/favourites' },
  { title: 'Black & White', path: '/black-and-white' },
  { title: 'Random', path: '/random' },
  { title: null, path: '#div2' },
  { title: 'Bavaria', path: '/bavaria' },
  { title: null, path: '#div1' },
  { title: 'Contact', path: '/contact' },
]

function Header() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const determineChildren = (children) => {
    if (!children) {
      return null;
    }
  
    return (
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {children.map(({ title, path }) => (
          <MenuItem onClick={handleClose} key={title}>{title}</MenuItem>
        ))}
      </Menu>
    )
  }

  const renderItemOrDivider = (title, path, children) => {
    if (title === null) {
      return (
        <Divider orientation='vertical' key={path} />
      )
    } else {
      return (
        <React.Fragment key={title}>
          <Link to={path} className={classes.linkText}>
            <ListItem button onClick={!!children ? handleClick : null}>
              <ListItemText primary={title} />
            </ListItem>
          </Link>
          <React.Fragment>
            { determineChildren(children) }
          </React.Fragment>
        </React.Fragment>
      )
    }
  }

  return (
    <HideOnScroll>
      <AppBar position="fixed" className={classes.toolbar}>
        <Toolbar id="back-to-top-anchor">
          <Container maxWidth={"lg"} className={classes.navbarDisplayFlex}>
            <IconButton component={Link} to="/" edge="start" color="inherit" aria-label="home">
              <Home fontSize="large"/>
            </IconButton>
            <Hidden smDown>
              <List component="nav" aria-labelledby="main navigation" className={classes.navDisplayFlex}>
                {navLinks.map(({ title, path, children }) => renderItemOrDivider(title, path, children) )}
              </List>
            </Hidden>
            <Hidden mdUp>
              <SideDrawer navLinks={navLinks} />
            </Hidden>
          </Container>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
}

export default Header;
