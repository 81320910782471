import Gallery from "../../components/Gallery/Gallery";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Typography} from "@material-ui/core";
import {Helmet} from "react-helmet";
import {useEffect, useState} from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '85px',
    padding: '15px',
  }
}));

const BlackAndWhite = () => {
  const classes = useStyles();
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetch("/img/black_and_white/black_and_white.json")
      .then(response => {
        return response.json();
      })
      .then(data => {
        setImages(data);
      });
  }, [setImages]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Black & White | Thomas Bezuidenhout</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h1" align="center">
            Black & White
          </Typography>
          <Typography gutterBottom={true} align="center">
            I kind of consider black and white images to be a form of cheating. Take a photo that looks plain, switch
            it to monochrome, and see the magic happen!
          </Typography>
          <Typography gutterBottom={true} align="center">
            These are a few of my favourite B&W photos I've taken over the past year.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Gallery images={images} />
        </Grid>
      </Grid>
    </div>
  )
};

export default BlackAndWhite;
